/* eslint-disable @typescript-eslint/no-explicit-any */

import Bugsnag from "@bugsnag/js";

if (process.env.NEXT_PUBLIC_BUGSNAG_API_KEY && process.env.NEXT_PUBLIC_ENV !== "test" && !(Bugsnag as any)._client) {
  const appType = process.env.NEXT_IS_SERVER ? "nextjs" : "browser";

  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY as string,
    appType,
    appVersion: process.env.NEXT_BUILD_ID as string,
    enabledBreadcrumbTypes: ["error", "log", "navigation", "request", "user"],
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: true,
    },
    enabledReleaseStages: ["demo", "development1", "development2", "development3", "development4", "production", "staging"],
    releaseStage: process.env.NEXT_PUBLIC_ENV,
  });
}

export const BugsnagClient = {
  notify(...args: Parameters<typeof Bugsnag.notify>): ReturnType<typeof Bugsnag.notify> {
    if (Bugsnag.isStarted()) {
      Bugsnag.notify(...args);
    } else {
      console.log(`Not sending error to server since Bugsnag is not initialized: ${args[0]}`);
    }
  },
  leaveBreadcrumb(...args: Parameters<typeof Bugsnag.leaveBreadcrumb>): ReturnType<typeof Bugsnag.leaveBreadcrumb> {
    if (Bugsnag.isStarted() && typeof window !== "undefined") {
      Bugsnag.leaveBreadcrumb(...args);
    }
  },
  addMetadata(...args: Parameters<typeof Bugsnag.addMetadata>): ReturnType<typeof Bugsnag.addMetadata> {
    if (Bugsnag.isStarted()) {
      Bugsnag.addMetadata(...args);
    }
  },
  setUser(...args: Parameters<typeof Bugsnag.setUser>): ReturnType<typeof Bugsnag.setUser> {
    if (Bugsnag.isStarted()) {
      Bugsnag.setUser(...args);
    }
  },
  setContext(...args: Parameters<typeof Bugsnag.setContext>): ReturnType<typeof Bugsnag.setContext> {
    if (Bugsnag.isStarted()) {
      Bugsnag.setUser(...args);
    }
  },
};
